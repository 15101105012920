<template>
  <div class="other-page-setting">
    <PageTitle title="其他頁面設定" btn="新增" @btnClick="$router.push({name: 'OtherPageEdit', query: {type: 'create'}})" />

    <el-table :data="pageConfigData">
      <EmptyBlock slot="empty" />
      <el-table-column label="名稱" prop="title" align="center" width="150" />
      <el-table-column align="center" />
      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="$router.push({name: 'OtherPageEdit', params: {pageKey: scope.row.key}, query: {type: 'edit'}})"
            @delete="deleteDialog=true, selectPage = scope.row.key"
          />
        </template>
      </el-table-column>
    </el-table>
    <!-- TODO Pagination -->
    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deletePage"
    />
  </div>
</template>

<script>
import EmptyBlock from '@/components/EmptyBlock.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { GetPageSetting, DeletePageSetting } from '@/api/ecommerce/pageSetting'
import { mapGetters } from 'vuex'
export default {
  name: 'OtherPageSetting',
  components: { DeleteDialog, EmptyBlock },
  data: () => ({
    selectPage: null,
    deleteDialog: false,
    pageConfigData: null,
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  async mounted () {
    await this.getPageSetting()
  },
  async activated () {
    await this.getPageSetting()
  },
  methods: {
    async getPageSetting () {
      const [res, err] = await GetPageSetting({ shopId: this.shop })
      if (err) return this.$message.error(err)
      this.pageConfigData = res
    },

    async deletePage () {
      const [, err] = await DeletePageSetting({ shopId: this.shop, key: this.selectPage })
      if (err) return this.$message.error(err)
      this.deleteDialog = false
      await this.getPageSetting()
    },
  },
}
</script>

<style lang="postcss" scoped>
.page-title {
  @apply text-[24px] font-medium;
}
</style>
